var nData = updateDatan();

    function getByIdn(id) {
        return _.findWhere(nData, {id: id});
    }
    // Получаем данные
    function updateDatan() {
        nData = JSON.parse(localStorage.getItem('nedavno')) || [];
        return nData;
    }

    function saveDatan() {
        localStorage.setItem('nedavno', JSON.stringify(nData));
        return nData;
    }

    function addsn(item) {
        var oldItem;
        updateDatan();
        oldItem = getByIdn(item.id);
        if(!oldItem) {
            nData.push(item);
        } else {
            oldItem.count = oldItem.count + item.count;
        } 
saveDatan();
}

var favData = updateDatafav();

    function getByIdfav(id) {return _.findWhere(favData, {id: id}); }
    function updateDatafav() {
        favData = JSON.parse(localStorage.getItem('favorites')) || [];
        return favData;    }
    function saveDatafav() {
        localStorage.setItem('favorites', JSON.stringify(favData));
        return favData;  }
    function addsfav(item) {
        var oldItem;
        updateDatafav();
        oldItem = getByIdfav(item.id);
        if(!oldItem) {
            favData.push(item);
        } else {
            oldItem.count = oldItem.count + item.count;
        } 
	saveDatafav(); }

    function removefav(id) {
        updateDatafav();
        favData = _.reject(favData, function(item) {
            return item.id === id;
        });
        saveDatafav();
        return favData;
    }
